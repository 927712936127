@import '../../styles/variables';
@import '../../styles/mixin';

.header {
  background: $background-header;
  background-image: $header-background-image;
  //TODO: we should find another solution for this, since backdrop-filter creates new stacking context and can sometimes lead to unexpected behavior
  backdrop-filter: $backdrop-filter;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-position: 50%;
  background-repeat: no-repeat !important; 
  background-size: cover !important;
  box-shadow: $header-shadow;
  user-select: none;
  z-index: 10;

  .header_mobile,
  .header_desktop {
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
  }

  .header_mobile {
    display: flex;

    @include media(lg) {
      display: none;
    }
  }

  .header_desktop {
    display: none;
    width: 100%;

    @include media(lg) {
      display: flex;
    }
  }

  button {
    svg {
      height: 24px;
      width: 24px;

      @media screen and (max-width: 400px) {
        width: 22px;
        height: 22px;
      }
    }
  }

  .header_row {
    width: 100%;
    border-bottom: $header-weight-border-bottom solid $header-border-bottom;

    &.with_navbar {
      background: $background-nav;
      background-image: $background-nav-image !important;
      backdrop-filter: $backdrop-filter-nav !important;
    }

    &.position {
      position: relative;
    }

    .header_row__container {
      margin: auto;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, auto));
      align-content: center;

      &:has([data-justify='center']) {
        grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));

        @include media(sm) {
          grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        }
      }

      .empty_section {
        display: none;
      }

      & > * {
        flex-grow: 1;
      }
    }

    &.borderless {
      border-bottom: 0;
    }

    &.flat {
      padding-block: 0;
    }

    &.small {
      padding-block: 0.5rem;
    }

    &.medium {
      padding-block: 0.75rem;
    }

    &.large {
      padding-block: 1rem;
    }

    .header_section {
      flex-shrink: 1;
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      min-width: 0;

      @include media(lg) {
        gap: 1rem;
      }

      &.space_between {
        &:has(> :nth-child(2)) {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
      }

      &[data-justify='start'] {
        justify-content: flex-start;
        margin-right: 0.5rem;
      }

      &[data-justify='center'] {
        justify-content: center;
      }

      &[data-justify='end'] {
        justify-content: flex-end;
        margin-left: 0.5rem;
      }
    }
  }

  .profile__action {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    &_label {
      text-transform: uppercase;
      color: $header-action-icon-color;
    }

    svg {
      width: 24px !important;
      height: 24px !important;

      @media screen and (max-width: 400px) {
        width: 24px !important;
        height: 24px !important;
      }

      * {
        stroke: $header-action-icon-color;
      }
    }
  }

  &__actions_icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 16px;

    @include media(md) {
      margin-left: auto;
      order: 3;
    }

    @include media(lg) {
      order: 4;
      margin-left: 0;
      padding: 16px 0;
      justify-content: flex-end;
    }

    @include media(2xl) {
      margin-left: 16px;
      column-gap: 24px;
    }

    .icons {
      &:hover {
        cursor: pointer;
      }
    }

    svg {
      width: 24px !important;
      height: 24px !important;

      @media screen and (max-width: 400px) {
        width: 24px !important;
        height: 24px !important;
      }

      * {
        stroke: $header-action-icon-color;
      }
    }
  }

  &__popover {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    width: 100%;

    @include media(md) {
      max-width: 380px;
      align-content: flex-start;
      order: 2;
    }

    @include media(xl) {
      max-width: 488px;
    }

    @include media(2xl) {
      max-width: 548px;
      column-gap: 24px;
    }

    > button {
      max-width: 376px;

      @include media(2xl) {
        max-width: 396px;
      }
    }
  }

  .align-logo {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .logo {
    @extend .align-logo;
    position: relative;
    margin-left: $logo-header-spacing-left;
    flex-shrink: 0;
    width: $header-logo-max-width-mobile;
    height: $header-logo-fix-height-mobile;
    z-index: 10;

    @include media(md) {
      width: 90px;
    }

    @include media(2xl) {
      width: 130px;
    }

    figure {
      @extend .align-logo;
      width: auto;
      height: $header-logo-fix-height-mobile;

      @include media(lg) {
        height: $header-logo-fix-height;
      }

      img {
        width: auto;
        max-height: $header-logo-fix-height-mobile;

        @include media(lg) {
          max-height: $header-logo-fix-height;
          height: auto;
        }
      }
    }

    &__fill {
      width: 100%;
      max-width: $header-logo-max-width-mobile;

      @include media(md) {
        width: 100%;
      }

      @include media(lg) {
        max-width: $header-logo-max-width;
        height: $header-logo-fix-height;
      }

      @include media(2xl) {
        width: 100%;
      }

      figure {
        width: 100%;
      }
    }

    &__center {
      figure {
        width: 100%;
        margin: 0 auto;
      }
    }

    &__fix_height {
      figure {
        width: 100%;
        background-color: $header-logo-background;
        backdrop-filter: $header-logo-backdrop-filter;
        border-radius: $header-logo-rounder;
      }
    }
  }

  button {
    &:focus:not(:focus-visible) {
      outline: 0;
    }
  }

  .container__popover {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;
    column-gap: 16px;

    @media screen and (min-width: 520px) {
      column-gap: 32px;
    }

    @include media(md) {
      column-gap: 16px;
      padding: 0;
    }
  }
}

.main-header-checkout {
  height: 80px;
  align-items: center;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container__menuNlogo {
    order: 1;

    .logo {
      left: 0px;
    }

    .logoSmall {
      left: 0px;
    }
  }
}

.section__nav {
  display: none;

  @include media(lg) {
    display: flex;
    flex-direction: row;
    height: 40px;
    position: sticky;
    z-index: 5;
  }
}
